



































































































































import Vue from 'vue';
import moment from 'moment-timezone';
import { setQueryAllAttribute } from '@/utils/DomUtil';
import { Component, Watch } from 'vue-property-decorator';

@Component
class RegisterComponent extends Vue {
    public pwShow1: boolean = false;
    public pwShow2: boolean = false;
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public password: string = '';
    public passwordVerify: string = '';
    public phone: string = '';
    public companyName: string = '';
    public valid: boolean = false;
    public timeZone = '';
    public timeZones = moment.tz.names();
    public message: string = '';
    public successMessage: string;
    public serverErrorMessage: string;
    public response: string = '';
    public verifyErrorMessages: object = [];
    public passwordErrorMessages: object = [];
    public hourFormat: number = 24;
    public hourFormats: number[] = [12, 24];
    public subscribed: boolean = true;

    public rules = {
        required: (value: string) => !!value || 'required field',
        password: (value: string) => value.trim().length >= 8 || 'At least 8 characters',
        email: (value: string) => {
            const pattern = new RegExp(''
                + /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@/.source
                + /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source,
            );
            return pattern.test(value) || 'Invalid e-mail.';
        },
        whitespace: (value: string) => {
            const pattern = new RegExp(/^(\S+\s*\S+)+$/);
            return pattern.test(value) || 'Password should not contain leading or trailing whitespace';
        },
        phone: (value: string) => {
            if (value) {
                const pattern = new RegExp(/^[+]?(?!-)([(][0-9]{1,3}[)])?([-\s]?[0-9])+$/);
                return pattern.test(value) || 'Invalid phone number';
            } else {
                return true;
            }
        },
        phoneMaxLength: (value: string) => {
            if (value) {
                return  value.trim().length < 21 || 'Phone number should not be longer than 20 characters';
            } else {
                return true;
            }
        },
    };

    public constructor() {
        super();
        this.successMessage = 'Please check your email and follow verification link';
        this.serverErrorMessage = 'server error';
    }

    public mounted(): void {
        setQueryAllAttribute('.v-icon', 'tabindex', '-1');
    }

    @Watch('email')
    public onEmailChanged() {
        this.emailMatch();
    }

    @Watch('password')
    public onPasswordChanged() {
        this.emailMatch();
        this.passwordMatch();
        this.addMessageOnEmptyPassword();
    }

    @Watch('passwordVerify')
    public onPasswordVerifyChanged() {
        this.passwordMatch();
        this.addMessageOnEmptyVerifyPassword();
    }

    public addMessageOnEmptyVerifyPassword() {
        if (this.passwordVerify.length === 0) {
            this.verifyErrorMessages = ['required field'];
        }
    }

    public addMessageOnEmptyPassword() {
        if (this.password.length === 0) {
            this.passwordErrorMessages = ['required field'];
        }
    }

    public passwordMatch() {
        if (this.passwordVerify.length !== 0) {
            if ( this.password !== this.passwordVerify) {
                this.verifyErrorMessages = ['Passwords you entered don\'t match'];
                this.valid = false;
            } else {
                this.verifyErrorMessages = [];
            }
        }
    }

    public emailMatch() {
        if (this.password.length !== 0) {
            if ( this.password === this.email) {
                this.passwordErrorMessages = ['Password should not be the same as email'];
                this.valid = false;
            } else {
                this.passwordErrorMessages = [];
            }
        }
    }

    public submit(): void {
        if ((this.$refs.form as any).validate() && this.valid) {
            this.$store.commit('notificationState/setSpinnerMessage', 'Sending verification email...');
            this.$store.dispatch('authState/registration', {
                firstName: this.firstName, lastName: this.lastName, email: this.email, password: this.password,
                phone: this.phone, timeZone: this.timeZone, companyName: this.companyName, hourFormat: this.hourFormat,
                subscribed: this.subscribed,
            })
                .then(response => {
                    this.$store.commit('notificationState/clearSpinnerMessage');
                    if (response.data.success) {
                        this.$store.commit('notificationState/setSuccessMessage', this.successMessage);
                        this.$router.push('login');
                    } else {
                        this.$store.commit('notificationState/setErrorMessage', response.data.error);
                    }
                })
                .catch(error => {
                    this.$store.commit('notificationState/clearSpinnerMessage');
                    this.$store.commit('notificationState/setErrorMessage', this.serverErrorMessage);
                });
        }
    }
}

export default RegisterComponent;
